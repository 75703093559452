<template>
  <span>
    <v-card
      class="notification"
      elevation="2"
      :class="`${drawer ? 'show' : ''} ${scrollTop && 'adjust-height'}`"
    >
      <v-card-title class="d-flex justify-space-between align-center">
        <div>
          <v-icon class="mr-2"> mdi-bell </v-icon>
          <span>Notification</span>
        </div>
        <span v-if="scrollTop">
          <v-btn
            min-width="0"
            text
            small
            @click="drawer = false"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </span>
      </v-card-title>
      <v-card-text>
        <v-list class="alertList">
          <v-list-item
            v-for="(item, index) in list"
            :key="index"
          >
            <v-list-item-content>
              <div class="alertWrapBox">
                <p>
                  <span class="alertName">{{ item.alert_name }}</span>
                  <span class="tenantName">{{ item.tenant_name }},</span>
                  <span class="lightGreyAlert">{{ item.vehicle_name }}</span>
                  <!-- <span class="regNo">LB-3090 (059)</span> -->
                  <span class="bottomAlertWrap">
                    <span class="alertType">{{ item.alert_type }}</span>
                    <span class="alertSep" />
                    <span class="alertDateTime">
                      {{ item.event_date_time }}
                    </span>
                  </span>
                </p>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="listLen == 0"
            class="inactive"
          >
            <v-list-item-subtitle>No record found</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <router-link to="/notifications"> See All Notifications </router-link>
        <!-- <div @click="drawer = false">
        </div> -->
      </v-card-actions>
    </v-card>
  </span>
</template>
<script>
import { WebPubSubClient } from "@azure/web-pubsub-client";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      messages: [],
      socket: null,
      //
      scrollTop: 0,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "alertsAndNotify/getLoading",
      list: "alertsAndNotify/getNotifyList",
    }),
    drawer: {
      get() {
        return this.$store.state.notifyDrawer;
      },
      set(val) {
        this.$store.commit("SET_NOTIFY_DRAWER", val);
      },
    },
    listLen() {
      return this.list.length;
    },
    groupName() {
      return this.authUser.group;
    },
  },
  watch: {
    authUser(v) {
      if (v?.pubsub_client_token) {
        if (!this.socket) {
          this.initializeWebSocket();
        }
      }
    },
    drawer(v) {
      //   when Open
      if (!v) {
        this.fetchData();
      }
    },
  },
  created() {},
  async mounted() {
    // this.initializeWebSocket();
    // this.initializeWebPubSub();
    this.fetchData();

    const body = document.body;

    const handleScroll = () => {
      this.scrollTop = body.scrollTop || document.documentElement.scrollTop;
    };

    window.addEventListener("scroll", handleScroll);
  },

  beforeDestroy() {
    if (this.socket) {
      this.socket.close(); // Close the WebSocket connection
    }
    this.socket = null; // Clear the socket reference
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("alertsAndNotify/notifyList", { limit: 15 });
      if (!this.socket) {
        this.initializeWebSocket();
      }
    },
    async initializeWebSocket() {
      const { pubsub_client_token } = this.authUser;
      const url = process.env.VUE_APP_PUBSUB_URL;
      const hubName = process.env.VUE_APP_PUBSUB_HUB_NAME;
      const WSURL = `${url}/${hubName}?access_token=${pubsub_client_token}`;

      this.socket = new WebSocket(WSURL);
      // Handle incoming messages
      this.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        this.$store.dispatch("alerts/info", data.message.complete_message);
        this.$store.commit("alertsAndNotify/ADD_NOTIFY_LIST", {
          ...data.message,
          isRead: false,
        });
      };

      // this.socket.onerror = (error) => {
      //   return error;
      // };
    },
    initializeWebPubSub() {
      const { pubsub_client_token } = this.authUser;
      const url = process.env.VUE_APP_PUBSUB_URL;
      const hubName = process.env.VUE_APP_PUBSUB_HUB_NAME;
      const CONNSTR = `${url}/${hubName}?access_token=${pubsub_client_token}`;
      const client = new WebPubSubClient(CONNSTR);

      // When the connection to Web PubSub is established
      client.on("connected", () => {});

      // Listen for messages from the Web PubSub hub
      client.on("message", (message) => {
        const receivedMessage = message.data;
        this.messages.push(receivedMessage); // Add the message to the messages array
      });

      // Start the connection to Web PubSub
      client.start().catch((err) => err);
    },
  },
};
</script>
<style lang="sass" scoped>
.notification
    cursor: pointer
    position: fixed
    z-index: 22
    top: 70px
    // top: 0
    right: 0
    width: 30%
    height: calc( 100vh - 70px )
    // height: 100vh
    overflow: hidden
    translate: 110%
    transition: all 0.4s linear
.show
    translate: 0

.adjust-height
  top: 0 !important
  height: 100vh

:deep .v-card__text
    height:  85%
    overflow-y: auto
    overflow-x: hidden

:deep .v-card__actions
  display: flex
  justify-content: center
  border-top: 1px solid lightgray

// :deep .primary
//     background-color: #20a390  !important
//     border-color: #20a390  !important

// :deep .primary--text
//     i
//         color: gray !important

:deep .v-list-item:not(:last-child)
    border-bottom: 1px solid lightgray

:deep .v-list-item
    user-select: none
    font-size: 12px
    transition: all 0.2s ease
    cursor: pointer
    &:hover
        // background: #20a390
        .v-list-item__content
            color: #20a390

:deep .v-list-item__subtitle
    display: flex
    justify-content: center

.inactive
    pointer-events: none !important
    border: none  !important

.msg
    display: flex
    justify-content: space-between
    align-items: center
    span
        flex: 1
    i
        color: #20a390

.unread
    font-weight: 600

@media only screen and (max-width: 960px)
    .notification
        width: 85%
</style>
